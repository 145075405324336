<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">assignment</i>
                </div>
                <h4 class="card-title">Comments</h4>
              </div>
              <div class="card-body">
                <div class="toolbar">
                  <!--        Here you can write extra buttons/actions for the toolbar              -->
                </div>
                <div class="material-datatables">
                  <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Comment</th>
                      <th>Date</th>
                      <th class="disabled-sorting text-right">Actions</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Comment</th>
                      <th>Created date</th>
                      <th class="text-right">Actions</th>
                    </tr>
                    </tfoot>
                    <tbody>
                    <tr v-for="comment in comments">
                      <td v-text="comment.name"></td>
                      <td v-text="comment.email"></td>
                      <td v-text="comment.comment"></td>
                      <td v-text="comment.created"></td>
                      <td class="text-right">
                        <a href="#" class="btn btn-link btn-danger btn-just-icon" @click="deleteComment(comment.comment_id)"><i class="material-icons">close</i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end content-->
            </div>
            <!--  end card  -->
          </div>
          <!-- end col-md-12 -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage},
  data() {
    return {
      comments: [],
    };
  },
  mounted: function(){
    this.refresh();
  },

  methods: {
    ...mapActions(["postJson"]),
    refresh(){
      this.getComments();
    },

    initTable: function (){

      setTimeout(function (){
        console.log('get image after 2s');


        // If table is initialized
        $('#datatables').DataTable().fnDestroy();

        $('#datatables').DataTable();

        // $('#datatables').DataTable().fnReloadAjax();
      }, 1500)
    },

    async getComments() {
      let app = this;
      let dataObject = {
        route: 'comment/view/',
      };
      let response = await this.postJson(dataObject)
      app.comments = response.message;
      app.initTable();
    },

    async deleteComment(id) {
      let app = this;
      let dataObject = {
        route: 'comment/delete/',
        id: id,
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();
      }
    },
  },
}
</script>
